<script>
import { mdiMinusCircle, mdiArrowRight } from '@mdi/js'

import { addIcons } from '@maxsystems/ui/vue'

addIcons({
  minusCircle: mdiMinusCircle,
  arrowRight: mdiArrowRight
})

export default {
  name: 'ExtendedDisclosures',

  props: {
    disclosures: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      subquestionsMap: {
        rust_damage_severity: 'rust_damage',
        body_damage_severity: 'body_damage',
        engine_issues_types: 'engine_issues',
        interior_issues_types: 'interior_issues',
        mechanical_issues_types: 'mechanical_issues',
        warning_lights_types: 'warning_lights',
        tire_issues_types: 'tire_issues',
        modifications_types: 'modifications'
      }
    }
  },

  computed: {
    formattedDisclosures () {
      const disclosures = []
      this.disclosures.forEach(disclosure => {
        if (this.subquestionsMap[disclosure.id]) {
          const mainQuestion = disclosures.find(
            mainQuestion =>
              mainQuestion.id === this.subquestionsMap[disclosure.id]
          )
          mainQuestion.subquestion = disclosure
        } else {
          disclosures.push(disclosure)
        }
      })
      this.disclosures.forEach(disclosure => {
        const { hasUpdatedAnswer, answer, original } = this.compareDisclosures(disclosure)
        disclosure.text = answer
        if (hasUpdatedAnswer) disclosure.previousText = original
      })
      return disclosures
    },

    columns () {
      return [
        { id: 1, disclosures: this.formattedDisclosures.slice(0, 5) },
        { id: 2, disclosures: this.formattedDisclosures.slice(5, 10) },
        { id: 3, disclosures: this.formattedDisclosures.slice(10) }
      ]
    }
  },

  methods: {
    getAnswer ({ answer, type, hasSubquestion, disclosure }) {
      const content = hasSubquestion
        ? disclosure.subquestion[answer]?.[type] !== undefined
          ? disclosure.subquestion[answer][type]
          : disclosure.subquestion[answer]
        : disclosure[answer]?.[type] !== undefined
          ? disclosure[answer][type]
          : disclosure[answer]

      const formatter = {
        boolean: answer => answer ? 'Yes' : 'No',
        text: answer => answer ?? 'None',
        choices: answer => answer === null ? 'None' : answer.sort().join(', ')
      }

      return formatter[type](content)
    },
    compareDisclosures (disclosure) {
      const hasSubquestion = !!disclosure.subquestion
      const [type] = hasSubquestion ? Object.keys(disclosure.subquestion.answer) : Object.keys(disclosure.answer)

      const original = this.getAnswer({ answer: 'original', type, hasSubquestion, disclosure })
      const answer = this.getAnswer({ answer: 'answer', type, hasSubquestion, disclosure })
      const hasUpdatedAnswer = original !== answer

      return {
        hasUpdatedAnswer,
        original,
        answer
      }
    }
  }
}
</script>

<template>
  <VContainer
    v-if="disclosures && disclosures.length"
    class="mb-5"
  >
    <VRow>
      <h4 class="font-weight-medium mb-2">
        Vehicle Condition
      </h4>
    </VRow>
    <VRow>
      <VCol
        v-for="col in columns"
        :key="col.id"
        cols="12"
        sm="4"
        class="pa-0 pr-4"
      >
        <VRow
          v-for="{ id, name, score, previousText, text } in col.disclosures"
          :key="id"
          :ref="id"
          no-gutters
          class="mb-1 align-start"
        >
          <VCol
            class="mr-6"
            cols="5"
          >
            <span class="text-body-2 text--secondary font-weight-normal">{{ name }}</span>
          </VCol>
          <VCol>
            <VIcon
              :class="[
                `${score === 'BAD' ? 'error' : 'success'}-dark`,
                'mr-1'
              ]"
              small
            >
              {{ score === 'BAD' ? '$minusCircle' : '$success' }}
            </VIcon>
            <span
              v-if="previousText"
              class="text-body-2"
            >
              <span class="previous-answer">{{
                previousText
              }}</span>
              <VIcon
                small
                class="ml-1"
              > $arrowRight </VIcon>
            </span>
            <span
              class="current-answer"
              :class="{
                'font-weight-bold': previousText,
                'text-body-2': true,
              }"
            >
              {{ text }}
            </span>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<style lang="scss" scoped>
.v-icon.v-icon {
  transform: translateY(-1px);
}

.success-dark.v-icon {
  color: var(--v-success-darken1);
}

.error-dark.v-icon {
  color: var(--v-error-darken2);
}

.previous-answer {
  border: none;
  text-decoration: line-through;
}
</style>
