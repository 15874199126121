/**
 * Retrieves the GraphQL token from the "com.max.apps.token" cookie.
 * This JavaScript cookie is used by mobile app embed version of the /offer page,
 * to bypass the authentication and authorization process via SSO/CAS.
 * @returns {string|null} The GraphQL token or null if not found.
 */
export function getAuthToken () {
  const authCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('com.max.apps.token='))
  const GraphQLToken = authCookie?.split('=')[1].trim()

  if (!GraphQLToken) return null
  return GraphQLToken
}
