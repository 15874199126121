<script>
import { VApp, VMain, VContainer } from 'vuetify/lib'

import Loading from './components/Loading'
import LoginForm from './components/LoginForm.vue'
import ToolbarApp from './components/ToolbarApp.vue'
import uiState from '~/observables/ui-state'
import { getAuthToken } from '~/utils/cookie-auth.js'

export default {
  components: {
    Loading,
    LoginForm,
    ToolbarApp,
    VApp,
    VMain,
    VContainer
  },
  inject: ['authData'],
  provide: { uiState },
  computed: {
    loading () {
      // @TODO: Client should better calculate the `pending` state.
      return this.authData.pending || (!this.authData.pending && !this.ready)
    },
    hideToolbar: ({ $route }) => Boolean($route.meta?.hideToolbar),
    ready: ({ authData }) => {
      const ssoAuth = authData.isLoggedIn && authData.dealer?.id
      return getAuthToken() || ssoAuth
    }
  }
}
</script>

<template>
  <VApp>
    <template v-if="ready">
      <template v-if="hideToolbar">
        <VMain>
          <router-view />
        </VMain>
      </template>

      <template v-else>
        <ToolbarApp />
        <VMain>
          <router-view />
        </VMain>
      </template>
    </template>

    <VContainer
      v-else
      class="justify-center flex-column fill-height"
    >
      <Loading v-if="loading" />
      <LoginForm v-else />
    </VContainer>
  </VApp>
</template>

<style>
html {
  overflow-y: hidden;
}
</style>
