var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disclosures && _vm.disclosures.length)?_c('VContainer',{staticClass:"mb-5"},[_c('VRow',[_c('h4',{staticClass:"font-weight-medium mb-2"},[_vm._v(" Vehicle Condition ")])]),_c('VRow',_vm._l((_vm.columns),function(col){return _c('VCol',{key:col.id,staticClass:"pa-0 pr-4",attrs:{"cols":"12","sm":"4"}},_vm._l((col.disclosures),function(ref){
var id = ref.id;
var name = ref.name;
var score = ref.score;
var previousText = ref.previousText;
var text = ref.text;
return _c('VRow',{key:id,ref:id,refInFor:true,staticClass:"mb-1 align-start",attrs:{"no-gutters":""}},[_c('VCol',{staticClass:"mr-6",attrs:{"cols":"5"}},[_c('span',{staticClass:"text-body-2 text--secondary font-weight-normal"},[_vm._v(_vm._s(name))])]),_c('VCol',[_c('VIcon',{class:[
              ((score === 'BAD' ? 'error' : 'success') + "-dark"),
              'mr-1'
            ],attrs:{"small":""}},[_vm._v(" "+_vm._s(score === 'BAD' ? '$minusCircle' : '$success')+" ")]),(previousText)?_c('span',{staticClass:"text-body-2"},[_c('span',{staticClass:"previous-answer"},[_vm._v(_vm._s(previousText))]),_c('VIcon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" $arrowRight ")])],1):_vm._e(),_c('span',{staticClass:"current-answer",class:{
              'font-weight-bold': previousText,
              'text-body-2': true,
            }},[_vm._v(" "+_vm._s(text)+" ")])],1)],1)}),1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }