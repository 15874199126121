<script>
import { client, withQuery } from '@maxsystems/client'
import { currency, number, phone } from '@maxsystems/ui/vue/filters'
import { getAppraisal } from '@maxsystems/xplat/core/data-source/queries'

import ExtendedDisclosures from './components/ExtendedDisclosures.vue'
import { getAuthToken } from '~/utils/cookie-auth.js'

export default {
  name: 'CustomerOffer',
  components: {
    ExtendedDisclosures
  },
  filters: {
    currency,
    phone
  },

  props: {
    print: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      loading: true,
      appraisal: null,
      error: null,
      query: withQuery(getAppraisal)
    }
  },

  computed: {
    disclosures: vm => vm.appraisal.valuation?.disclosures,
    updatedAt: vm => vm.appraisal.offer?.updatedAt,
    // Offer expires 7 days after it was last updated (or created)
    expiresAt ({ updatedAt }) {
      if (!updatedAt) return false

      const date = new Date(updatedAt)
      date.setDate(date.getDate() + 7)
      return date
    },

    mileage () {
      if (!this.appraisal.vehicle.odometer?.value) return
      return number(this.appraisal.vehicle.odometer.value)
    },

    amount: ({ appraisal }) => appraisal?.offer?.value?.amount,
    originalAmount: ({ appraisal }) => appraisal?.offer?.original?.amount,

    vehicleModel () {
      const { year, make, model, trim } = this.appraisal.vehicle.ymmt
      // Merge data ignoring (not expected) null values
      return [year, make, model, trim].join(' ')
    },

    address () {
      const { street, locality, region, postalCode } = this.appraisal.dealer.address || {}
      return { street, locality, region, postalCode }
    },

    dealerWebsite: ({ appraisal }) => {
      const url = appraisal?.dealer?.url
      if (!url) return null
      if (url.startsWith('http')) return url
      // Prepend protocol since some dealers have it missing due to configuration issue
      return `https://${url}`
    },

    dealerWebsiteHost: ({ dealerWebsite }) => {
      try {
        return new URL(dealerWebsite).host
      } catch {
        return null
      }
    },

    expirationDate () {
      const options = { month: 'long', day: 'numeric', year: 'numeric' }
      return this.expiresAt.toLocaleString('en-US', options)
    },

    hasExpired () {
      return new Date(Date.now()) >= this.expiresAt
    },

    remainingDays () {
      if (this.hasExpired) return 'This offer has expired'

      const diffTime = Math.abs(new Date(Date.now()) - this.expiresAt)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      const remainingDays = diffDays > 1 ? `${diffDays} days` : '1 day'
      return `This offer is good for ${remainingDays}. After ${remainingDays} (or if vehicle is driven more than 150 miles)`
    },

    profile: vm => vm.appraisal?.customer?.profile
  },

  async created () {
    /**
     * Inject a GraphQL token for mobile app flow, without
     * requiring the user to log in again using SSO/CAS.
     */
    const graphQLToken = getAuthToken()
    if (graphQLToken) {
      client.token = `Bearer ${graphQLToken}`
    }

    this.$vuetify.theme.dark = false
    await this.getAppraisal(this.$route.params.id)
    this.loading = false
  },

  methods: {
    async getAppraisal (id) {
      const { data, errors } = await this.query.fetch({ id })
      if (errors && !data?.appraisal) {
        this.error = true
        return
      }
      this.appraisal = data.appraisal
    }
  }
}
</script>

<template>
  <VContainer
    class="container--customer-offer pa-8 pa-md-16"
    :class="{ print }"
    fluid
  >
    <VOverlay
      v-if="loading"
      absolute
      :value="loading"
    >
      <VProgressCircular
        indeterminate
        size="64"
      />
    </VOverlay>
    <template v-else>
      <template v-if="appraisal">
        <img
          v-if="appraisal.dealer.logo"
          :src="appraisal.dealer.logo.url"
          class="container--customer-offer--dealer-logo"
          :alt="appraisal.dealer.name"
        >
        <section class="offer__header text-center mb-10 pb-10">
          <h1 class="offer__title text-h5 text--secondary mb-2">
            Customer Offer
          </h1>
          <h2
            v-if="amount"
            ref="amount"
            class="offer__amount text-h2 font-weight-bold mb-6"
          >
            {{ amount | currency }}
          </h2>
          <h4 class="offer__model text-h4 mb-3">
            {{ vehicleModel }}
          </h4>
          <div class="text--secondary d-inline-flex justify-space-around flex-column flex-sm-row">
            <span class="pa-1">VIN: {{ appraisal.vehicle.vin }}</span>
            <span
              v-if="mileage"
              ref="mileage"
              class="pa-1 ml-sm-10"
            >Mileage {{ mileage }}</span>
          </div>
        </section>
        <ExtendedDisclosures
          class="vehicle-conditions"
          :disclosures="disclosures"
        />
        <VContainer
          v-if="(originalAmount || amount) && (amount !== originalAmount)"
          ref="adjustedOffer"
          class="offer-values pa-0 mb-5"
        >
          <VRow no-gutters>
            <VCol
              xs="6"
              sm="3"
              md="2"
            >
              <h4 class="font-weight-regular mb-2">
                Original offer
              </h4>
            </VCol>
            <VCol
              xs="6"
              sm="3"
              md="2"
            >
              <span v-if="originalAmount">{{ originalAmount | currency }}</span>
              <span v-else> - </span>
            </VCol>
          </VRow>
          <VRow no-gutters>
            <VCol
              xs="6"
              sm="3"
              md="2"
            >
              <h4 class="font-weight-regular">
                Adjusted offer
              </h4>
            </VCol>
            <VCol
              xs="6"
              sm="3"
              md="2"
            >
              <span v-if="amount">{{ amount | currency }}</span>
              <span v-else> - </span>
            </VCol>
          </VRow>
        </VContainer>
        <VContainer class="pa-0 my-2">
          <div class="info-container d-flex flex-column flex-md-row justify-space-between gap-4">
            <div
              v-if="profile"
              ref="profile"
              class="info-box grey lighten-5 text-body-2 mb-4 pa-4"
            >
              <VRow
                class="mb-5"
                no-gutters
              >
                <VCol class="text-subtitle-1 font-weight-medium text-uppercase text-center text--secondary">
                  {{ profile.name }}
                </VCol>
              </VRow>
              <VRow
                no-gutters
                class="mb-1"
              >
                <VCol cols="5">
                  Phone Number
                </VCol>
                <VCol cols="7">
                  {{ profile.phone | phone }}
                </VCol>
              </VRow>
              <VRow
                no-gutters
                class="mb-1"
              >
                <VCol cols="5">
                  Email Address
                </VCol>
                <VCol>
                  {{ profile.email }}
                </VCol>
              </VRow>
            </div>
            <div
              ref="dealer"
              class="info-box grey lighten-5 text-body-2 mb-4 pa-4"
              :class="{ 'info-box--centered': !profile }"
            >
              <VRow
                class="mb-5"
                no-gutters
              >
                <VCol class="text-subtitle-1 font-weight-medium text-uppercase text-center text--secondary">
                  {{ appraisal.dealer.name }}
                </VCol>
              </VRow>
              <VRow
                class="mb-1"
                no-gutters
              >
                <VCol cols="5">
                  Dealer Address
                </VCol>
                <VCol cols="7">
                  {{ address.street }}<br>{{ address.locality }}, {{ address.region }} {{ address.postalCode }}
                </VCol>
              </VRow>
              <VRow
                class="mb-1"
                no-gutters
              >
                <VCol cols="5">
                  Dealer Phone
                </VCol>
                <VCol cols="7">
                  {{ appraisal.dealer.phone | phone }}
                </VCol>
              </VRow>
              <VRow
                v-if="dealerWebsite"
                class="mb-1"
                no-gutters
              >
                <VCol cols="5">
                  Dealer Website
                </VCol>
                <VCol>
                  <a
                    ref="noopener"
                    class="black--text"
                    :href="dealerWebsite"
                    target="_blank"
                  >{{ dealerWebsiteHost }}</a>
                </VCol>
              </VRow>
            </div>
          </div>
        </VContainer>
        <VContainer
          v-if="amount"
          class="pa-0 text-body-2 mb-6 notes"
        >
          <VRow no-gutters>
            <VCol
              ref="validity"
              xs="12"
              sm="6"
              class="valid-through"
            >
              <div class="mb-4">
                {{ !hasExpired && expirationDate ? `Valid Through ${expirationDate}` : 'Expired' }}
              </div>
              <div class="text--secondary">
                {{ remainingDays }}, your vehicle will need to be reappraised and the offer may change.
              </div>
            </VCol>
          </VRow>
        </VContainer>
      </template>
      <div v-if="!appraisal">
        Appraisal not found
      </div>
      <div v-if="error">
        An error occurred
      </div>
    </template>
  </VContainer>
</template>

<style>
html {
  overflow-y: unset;
}
</style>

<style lang="scss">
.container--customer-offer {
  max-width: 1100px;
  -webkit-print-color-adjust: exact;

  &--dealer-logo {
    width: 200px;
  }

  .details-info {
    font-size: 14px;
  }

  .dashed {
    border: none;
    border-bottom: 1px dashed rgb(0 0 0 / 38%);
  }
}

.info-box {
  flex: 1;
  margin-right: 16px;

  // only if tablet or desktop media query
  @media (min-width: 960px) {
    max-width: 470px;

    &--centered {
      margin: 0 auto;
    }
  }
}

@mixin print-styles {
  .container--customer-offer {
    padding: 0 24px !important;
  }

  .info-container {
    flex-direction: row !important;
  }

  .offer {
    &__header {
      margin-bottom: 16px !important;
      padding-bottom: 0 !important;
    }

    &__title {
      font-size: 1.4rem !important;
      margin-bottom: 0 !important;
    }

    &__amount {
      font-size: 2.8rem !important;
      margin-bottom: 0 !important;
    }

    &__model {
      font-size: 1.8rem !important;
    }
  }

  .valid-through {
    flex: 0 0 60%;
    max-width: 100% !important;
    width: 100% !important;

    & > div {
      margin-bottom: 0 !important;
    }
  }

  .notes {
    margin: 0;
  }
}

@media print {
  .v-application {
    @include print-styles;
  }
}

@media print and (min-width: 600px) {
  .v-application {
    .container--customer-offer {
      &--dealer-logo {
        left: 20px;
        position: absolute;
        top: 0;
      }
    }
  }
}

/*
 * Container to emulate the US Letter size (96 PPI)
 * in a portrait orientation on Storybook
 * to prevent regressions
 */
$us-letter-width: 816px;
$us-letter-height: 1054px;

@mixin us-letter-page {
  box-shadow:
    rgb(0 0 0 / 25%) 0 54px 55px,
    rgb(0 0 0 / 12%) 0 -12px 30px,
    rgb(0 0 0 / 12%) 0 4px 6px,
    rgb(0 0 0 / 17%) 0 12px 13px,
    rgb(0 0 0 / 9%) 0 -3px 5px;
  height: $us-letter-height;
  width: $us-letter-width;
}

.print {
  @include us-letter-page;

  position: relative;

  @include print-styles;
}

</style>
