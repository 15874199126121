import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth, authData, client } from '@maxsystems/client'

import Dashboard from './Dashboard.vue'
import VDP from './VDP/index.vue'
import { getAuthToken } from '~/utils/cookie-auth.js'

Vue.use(VueRouter)

const routes = [
  {
    name: 'dashboard',
    path: '/',
    component: Dashboard,
    children: [
      {
        name: 'CustomerOffer',
        path: '/:id/offer',
        component: () => import(
          /* webpackChunkName: "customer-offer" */
          './CustomerOffer/index.vue'
        ),
        meta: { hideToolbar: true }
      },
      {
        name: 'vdp',
        path: ':id?',
        component: VDP,
        props: true
      },
      {
        // temporary route while waiting for plugin system approach to be implemented
        // similar to lilypad: https://github.com/maxsystems/lilypad/blob/main/src/patterns/filterable/README.md
        name: 'vdp.widget.carfax.detail',
        path: ':id/:widgetId(carfax)',
        props: { default: true },
        components: {
          default: VDP,
          detail: () => import(
            /* webpackChunkName: "vdp.widget.detail" */
            '@maxsystems/xplat/web/widgets/carfax/CarfaxWidgetDetail.vue'
          )
        }
      },
      {
        // temporary route while waiting for plugin system approach to be implemented
        // similar to lilypad: https://github.com/maxsystems/lilypad/blob/main/src/patterns/filterable/README.md
        name: 'vdp.widget.window-sticker.detail',
        path: ':id/:widgetId(window-sticker)',
        props: { default: true },
        components: {
          default: VDP,
          detail: () => import(
            /* webpackChunkName: "vdp.widget.detail" */
            '@maxsystems/xplat/web/widgets/window-sticker/WindowStickerWidgetDetail.vue'
          )
        }
      },
      {
        // temporary route while waiting for plugin system approach to be implemented
        // similar to lilypad: https://github.com/maxsystems/lilypad/blob/main/src/patterns/filterable/README.md
        name: 'vdp.widget.acv.detail',
        path: ':id/:widgetId(acv)',
        props: { default: true },
        components: {
          default: VDP,
          detail: () => import(
            /* webpackChunkName: "vdp.widget.detail" */
            '@maxsystems/xplat/web/widgets/acvEstimate/detail/index.vue'
          )
        }
      },
      {
        // manage invalid widgetId by redirecting to appraisal's VDP root
        path: ':id/:widgetId',
        redirect: { name: 'vdp' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Next-gen Appraisals use the MongoDB ObjectId as Id
 * Id that are 12 bytes in length or 24 hexadecimal
 * (new Uint16Array(new Uint8Array(new ArrayBuffer(12)))).byteLength // 24
 * @see https://www.mongodb.com/docs/manual/reference/bson-types/#objectid
 */
const nextGenAppraisalIdLength = 24

export function beforeEach (to, from, next) {
  const appraisalId = to.params?.id
  const isNextGenAppraisalId = appraisalId?.length === nextGenAppraisalIdLength

  // Enable next-gen header by default or based on the Appraisal Id
  if (!appraisalId || isNextGenAppraisalId) client.previews.add('appraisals.next')

  if (getAuthToken()) return next()

  if (!authData.isLoggedIn) auth.setup()
  next()
}
router.beforeEach(beforeEach)

export default router
